@media (min-width: 1024px) {
  /* Track */
  .scrollbar::-webkit-scrollbar {
    width: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: rgb(var(--core-1));
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(var(--core-2));
    transition: background-color 5s ease;
  }

  .scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: rgb(var(--core-3));
  }

  .scrollbar::-webkit-scrollbar-thumb {
    border: 7px solid rgb(var(--core-1));
    border-top-width: 10px;
    border-bottom-width: 10px;
    width: 8px;
  }
}
